import "./about.css";
import Navbar from "../../components/Navbar/navbar";
import Footer from "../../components/footer/Footer";
import aboutHeroImage from "../../assets/about-hero-image.png";
import { useNavigate } from "react-router-dom";
import { EMPLOYEE__PROFILE } from "../../config/profileData";

function About() {
  const navigate = useNavigate();

  return (
    <>
      <div className="about">
        <Navbar />
        <section className="about__section__one">
          <h3>About us</h3>
          <h1>
            Your trusted <b>partner</b> in digital service
          </h1>
          <img alt="hero image" src={aboutHeroImage} />
        </section>
        <section className="about__section__two">
          <button>TEAM</button>
          <h1>Our Talented People</h1>
          <p>
            We bring ideas to life by combining years of experiences of our
            talented team.
          </p>
          <div className="about__section__two__staff">
            {EMPLOYEE__PROFILE.map((item, index) => {
              return (
                <div
                  className="about__section__two__staff__card"
                  onClick={() => navigate(`/profile/${item.id}`)}
                >
                  <div className={`folder${index % 2 === 0 ? "" : " alt"}`}>
                    <div className="folder-top"></div>
                    <div className="folder-body">
                      <img
                        src={item.image}
                        alt="Cut-out person"
                        className="cutout-image"
                      />
                    </div>
                  </div>
                  <h1>{item.name}</h1>
                  <p>{item.position}</p>
                </div>
              );
            })}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default About;
