import { useParams } from "react-router-dom";
import "./profile.css";
import { EMPLOYEE__PROFILE } from "../../config/profileData";
import Navbar from "../../components/Navbar/navbar";
import Footer from "../../components/footer/Footer";
import { MdDoubleArrow } from "react-icons/md";

function Profile() {
  const params = useParams();

  const profile = EMPLOYEE__PROFILE.find(
    (employee) => employee.id == params.id
  );

  return (
    <>
      <div className="profile__bg">
        <Navbar />
        <div className="profile__main">
          <h1>
            Team <MdDoubleArrow /> {profile.name}
          </h1>
          <div className="profile">
            <div className="profile__header">
              <img src={profile.image} alt={profile.name} />
            </div>
            <div className="profile__body">
              <div className="folder profile">
                <div className="folder-top"></div>
                <div className="folder-body profile">
                  <h3>Name:</h3>
                  <p>{profile.name}</p>
                  <h3>position:</h3>
                  <p>{profile.position}</p>
                  <h3>Bio:</h3>
                  <p>{profile.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Profile;
