import React, { useState } from 'react';
import Switch from 'react-switch';
import Navbar from '../../components/Navbar/navbar';
import './pricing.css';
import Footer from '../../components/footer/Footer';
import { useNavigate } from 'react-router-dom';

function Pricing() {
    const [isYearly, setIsYearly] = useState(false);
    const navigate = useNavigate();

    const handleToggle = () => {
        setIsYearly(!isYearly);
    };

    const handlePlanSelection = (plan, price) => {
        navigate(`/?plan=${plan}&price=${price}#geetek-form`);
    };

    // Define constants for prices
    const monthlyPrices = {
        website: 989.99,
        branding: 1499.00,
        mobileApp: 3333.00
    };

    // Calculate yearly prices
    const yearlyPrices = {
        website: Math.round(monthlyPrices.website * 12),
        branding: Math.round(monthlyPrices.branding * 12),
        mobileApp: Math.round(monthlyPrices.mobileApp * 12)
    };

    return (
        <div className='pricing'>
            <div className="Navbar">
                <Navbar />
            </div>
            <div className="pricingContainers">
                <div className="pricingTextContainers">
                    <div className="firstContainer">
                        <p className='firstText'>PRICING</p>
                    </div>
                    <div className="secondContainer">
                        <p className='secondText'>Pricing Plans</p>
                    </div>
                    <div className="thirdContainer">
                        <p className='thirdText'>Check our easy and simple plans</p>
                    </div>
                    {/* <div className="fourthContainer">
                        <span className='fourthText'>Monthly</span>
                        <Switch
                            checked={isYearly}
                            onChange={handleToggle}
                            offColor='#E8E6E4'
                            onColor="#E8E6E4"
                            onHandleColor="#265375"
                            offHandleColor="#265375"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={42}
                            width={90}
                            className="react-switch"
                            id="material-switch"
                        />
                        <span className='fourthText'>Yearly</span>
                    </div> */}
                </div>
                <div className="pricingCardContainers">
                    <div className="pricingCards1">
                        <div className="pricingCards1-top"></div>
                        <h2>Website Development Pack</h2>
                        <p>Get unlimited services retainer</p>
                        <span className="price1">
                            Starting from ${monthlyPrices.website}
                            {/* {isYearly ? `$${yearlyPrices.website}/per year` : `$${monthlyPrices.website}/per month`} */}
                        </span>
                        <h3>Includes:</h3>
                        <ul>
                            <li> <img src="images/price-tick.svg" alt="tick" />Company Rebranding</li>
                            <li> <img src="images/price-tick.svg" alt="tick" />SEO</li>
                            <li> <img src="images/price-tick.svg" alt="tick" />Full Website Building</li>
                            <li> <img src="images/price-tick.svg" alt="tick" />1 Year Hosting</li>
                            <li> <img src="images/price-tick.svg" alt="tick" />Free Customized Emails</li>
                            <li> <img src="images/price-tick.svg" alt="tick" />Consulting</li>
                        </ul>
                        <button className="plan-button1" onClick={() => handlePlanSelection('Website Development Pack', isYearly ? yearlyPrices.website : monthlyPrices.website)}>Get This Plan</button>
                    </div>
                    <div className="pricingCards2">
                        <div className="pricingCards2-top"></div>
                        <h2>Branding Pack</h2>
                        <p>Get access to all of our services</p>
                        <span className="price2">
                        Starting from ${monthlyPrices.branding}
                            {/* {isYearly ? `$${yearlyPrices.branding}/per year` : `$${monthlyPrices.branding}/per month`} */}
                        </span>
                        <h3>Includes:</h3>
                        <ul>
                            <li> <img src="images/price-black-tick.svg" alt="tick" />Company Logo</li>
                            <li> <img src="images/price-black-tick.svg" alt="tick" />20 Flyers</li>
                            <li> <img src="images/price-black-tick.svg" alt="tick" />Brand Consultancy</li>
                            <li> <img src="images/price-black-tick.svg" alt="tick" />Social Media Management</li>
                            <li> <img src="images/price-black-tick.svg" alt="tick" />Company Profile</li>
                            <li> <img src="images/price-black-tick.svg" alt="tick" />Marketing Consulting</li>
                        </ul>
                        <button className="plan-button2" onClick={() => handlePlanSelection('Branding Pack', isYearly ? yearlyPrices.branding : monthlyPrices.branding)}>Get This Plan</button>
                    </div>
                    <div className="pricingCards2">
                        <div className="pricingCards2-top"></div>
                        <h2>Mobile App Development</h2>
                        <p>Get access to all of our services</p>
                        <span className="price2">
                        Starting from ${monthlyPrices.mobileApp}
                            {/* {isYearly ? `$${yearlyPrices.mobileApp}/per year` : `$${monthlyPrices.mobileApp}/per month`} */}
                        </span>
                        <h3>Includes:</h3>
                        <ul>
                            <li> <img src="images/price-black-tick.svg" alt="tick" />App development</li>
                            <li> <img src="images/price-black-tick.svg" alt="tick" />Website Development</li>
                            <li> <img src="images/price-black-tick.svg" alt="tick" />Hosting/ Deployment</li>
                            <li> <img src="images/price-black-tick.svg" alt="tick" />Desiigns</li>
                            <li> <img src="images/price-black-tick.svg" alt="tick" />Marketing</li>
                            <li> <img src="images/price-black-tick.svg" alt="tick" />Consulting</li>
                        </ul>
                        <button className="plan-button2" onClick={() => handlePlanSelection('Mobile App Development', isYearly ? yearlyPrices.mobileApp : monthlyPrices.mobileApp)}>Get This Plan</button>
                    </div>
                </div>
            </div>
            <div className="Footer">
                <Footer />
            </div>
        </div>
    );
}

export default Pricing;