import React, { useContext, useEffect, useState } from "react";
import { Toggle } from "../ui/darkmode-toggle/Toggle";
import AuthContext from "../../context/AuthProvider";
import { RiMenu2Fill } from "react-icons/ri";
import { useNavigate, useLocation } from "react-router-dom";
import "./navbar.css";

import navLogoDark from "../../assets/logodark.png";
import navLogo from "../../assets/logo.png";

function Navbar() {
  // Access useContext state variables
  const { isDark, setIsDark } = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();
  const [activeId, setActiveId] = useState(1);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case "/":
        setActiveId(1);
        break;
      case "/about":
        setActiveId(2);
        break;
      case "/work":
        setActiveId(3);
        break;
      case "/pricing":
        setActiveId(4);
        break;
      default:
        setActiveId(1);
        break;
    }
  }, [location]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleNavigation = (path, id) => {
    navigate(`/${path}`);
    setSidebarOpen(false);
    window.scrollTo(0, 0);
  };

  const handleButtonClick = () => {
    window.open("https://wa.me/qr/CDUXCHGLTIMRE1", "_blank");
  };

  return (
    <div className="navbar">
      <div className="nav-img-container">
      {isDark ? (
          <img src={navLogoDark} alt="logo" className="nav-img" onClick={() => handleNavigation("", 1)}/>
        ) : (
          <img src={navLogo} alt="logo" className="nav-img" onClick={() => handleNavigation("", 1)}/>
        )}
      </div>
      <div className="nav-btn-container">
        <button
          className={`nav-btn${activeId === 1 ? "-act" : ""}`}
          onClick={() => handleNavigation("", 1)}
        >
          Home
        </button>
        <button
          className={`nav-btn${activeId === 2 ? "-act" : ""}`}
          onClick={() => handleNavigation("about", 2)}
        >
          About
        </button>
        <button
          className={`nav-btn${activeId === 3 ? "-act" : ""}`}
          onClick={() => handleNavigation("work", 3)}
        >
          Work
        </button>
        <button
          className={`nav-btn${activeId === 4 ? "-act" : ""}`}
          onClick={() => handleNavigation("pricing", 4)}
        >
          Pricing
        </button>
      </div>
      <div className="contact-btn-contianer">
      <Toggle
          isClicked={isDark}
          handleChange={() => {
            setIsDark(!isDark);
          }}
        />
        <button className="contact-btn" onClick={handleButtonClick}>
          Contact Us
          <img src="./images/Vector.svg" alt="logo" className="vector-img" />
        </button>
      </div>
      <div className="sidebar-btn-container">
        <Toggle
          isClicked={isDark}
          handleChange={() => {
            setIsDark(!isDark);
          }}
        />
        <button className="sidebar-btn" onClick={toggleSidebar}>
          <RiMenu2Fill className="sidebar-img" />
        </button>
      </div>
      <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
        <button className="close-btn" onClick={toggleSidebar}>
          X
        </button>
        <button
          className={`sidebar-nav-btn${activeId === 1 ? "-act" : ""}`}
          onClick={() => handleNavigation("", 1)}
        >
          Home
        </button>
        <button
          className={`sidebar-nav-btn${activeId === 2 ? "-act" : ""}`}
          onClick={() => handleNavigation("about", 2)}
        >
          About
        </button>
        <button
          className={`sidebar-nav-btn${activeId === 3 ? "-act" : ""}`}
          onClick={() => handleNavigation("work", 3)}
        >
          Work
        </button>
        <button
          className={`sidebar-nav-btn${activeId === 4 ? "-act" : ""}`}
          onClick={() => handleNavigation("pricing", 4)}
        >
          Pricing
        </button>
        <button className="sidebar-nav-btn" onClick={handleButtonClick}>
          Contact Us
        </button>
      </div>
      <div
        className={`overlay ${sidebarOpen ? "open" : ""}`}
        onClick={toggleSidebar}
      ></div>
    </div>
  );
}

export default Navbar;
