import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../../components/Navbar/navbar';
import './blog.css';
import Footer from '../../components/footer/Footer';

function Blog() {
  const location = useLocation();
  const { state } = location;

  const { title, subtitle, image } = state;

  return (
    <div className='blogContainer'>
      <Navbar />
      <div className="blogContentsContainer">
        <div className="blogContents">
          <h3>Blog</h3>
          <div className="blogDetails">
            <img src={image} alt="Blog Image" className="blogImage" />
            <h1>{title}</h1>
            <p>{subtitle}</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blog;