import { PiSunLight } from "react-icons/pi";
import "./toggle.css";

export const Toggle = ({ handleChange, isClicked }) => {
  return (
    <div className="toggle__container">
      <PiSunLight
        className={`toggle${isClicked ? " clicked" : ""}`}
        onClick={handleChange}
      />
    </div>
  );
};
