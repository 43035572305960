import { createContext, useState } from "react";
import useLocalStorage from "use-local-storage";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    user: null,
    accessToken: null,
  });

  const preference = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDark, setIsDark] = useLocalStorage("isDark", preference);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        isDark,
        setIsDark,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
