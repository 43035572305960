import staffImage from "../assets/staff.png";

export const EMPLOYEE__PROFILE = [
  {
    id: 1,
    image: staffImage,
    name: "John Doe",
    position: "Software Engineer",
    description:
      "John Doe is a talented software engineer with a passion for technology and innovation. He is a dedicated and focused individual who thrives on challenging problems and pushing boundaries. Ipsum exercitation sit id qui labore reprehenderit mollit est sit ullamco eu aliquip eiusmod.Ad ipsum culpa mollit sunt anim. Ad in laborum consectetur commodo aliquip esse eiusmod. Consectetur dolore in officia sint minim aliquip reprehenderit reprehenderit reprehenderit elit cillum do quis. Incididunt irure non amet consectetur officia quis in do. Do cillum occaecat velit duis ea magna cupidatat ex velit ex anim. Ex minim minim elit tempor laboris eiusmod excepteur anim in fugiat.Sunt aute incididunt reprehenderit sit esse laboris irure minim cillum ex laborum esse ullamco. Esse Lorem qui excepteur reprehenderit elit non mollit dolore cupidatat. Sunt officia anim cillum enim cupidatat laborum. In ad sit nisi Lorem aliquip.",
  },
  {
    id: 2,
    image: staffImage,
    name: "Jane Smith",
    position: "Product Manager",
    description:
      "Jane Smith is a highly skilled and experienced product manager with a focus on building successful and innovative products. She is a natural leader and motivator who values collaboration and teamwork.Consectetur consectetur consectetur aute occaecat ut laboris.Do eu nisi ullamco et reprehenderit ut esse ut dolor. Labore dolore ut aliqua laboris. Minim cillum fugiat quis dolor et ut labore veniam nisi eiusmod ad non cillum. Duis exercitation officia id quis nostrud et aute laborum qui aliqua deserunt incididunt ullamco.Amet cupidatat veniam labore labore anim elit veniam aliqua ut id eu exercitation irure nostrud. Aute in voluptate velit commodo in excepteur dolor aliquip sint ex aute. Labore irure aliqua reprehenderit est. Minim nostrud do sit sit ut ad deserunt ad anim irure voluptate. Elit eu eu sint sit aliquip officia occaecat aute laborum. Ullamco ad cillum in proident commodo minim aliqua incididunt.",
  },
  {
    id: 3,
    image: staffImage,
    name: "Michael Johnson",
    position: "UX Designer",
    description:
      "Michael Johnson is a creative and innovative UX designer with a passion for user experience and design. He is a detail-oriented and focused individual who loves to collaborate with team members to create beautiful and intuitive user interfaces.Nostrud laboris occaecat exercitation incididunt.Esse proident deserunt ut sunt eiusmod culpa. Ea non amet quis aliquip pariatur sint consectetur do ad ea magna velit. Ullamco mollit exercitation cupidatat est reprehenderit. Ipsum non Lorem consectetur consequat consequat cillum ipsum. Consequat irure non reprehenderit ut laborum est quis et irure deserunt consequat. Aliqua laboris est voluptate magna magna aliquip deserunt eiusmod duis ipsum id. Elit excepteur deserunt ea in aliqua quis anim.Aliquip aliqua consequat ad sit proident dolore commodo quis fugiat. Velit deserunt fugiat mollit ipsum commodo dolore aliqua magna amet ullamco exercitation cupidatat in magna. Elit deserunt velit consequat exercitation laborum qui nostrud in. Enim dolor fugiat incididunt proident consectetur dolore pariatur do ipsum qui irure proident nulla. Enim aliquip veniam est cillum aute aliquip ad. Nisi anim ullamco aliqua ad.",
  },
  {
    id: 4,
    image: staffImage,
    name: "Sarah Williams",
    position: "HR Manager",
    description:
      "Sarah Williams is a dedicated and focused HR manager with a focus on helping clients achieve their goals and delivering exceptional customer service. She is a strong team player and motivator who values collaboration and communication.Adipisicing sit dolor nulla excepteur sint.Dolore amet id aliquip quis cupidatat do veniam mollit minim qui ipsum. Cupidatat voluptate sint tempor pariatur pariatur exercitation duis quis mollit. Excepteur occaecat commodo nulla tempor excepteur officia.Et nulla laborum non in labore dolore fugiat. Cupidatat cupidatat nulla qui eiusmod deserunt exercitation ut proident aliquip id. Exercitation adipisicing nulla irure commodo. Laborum qui nostrud laborum aute occaecat esse.",
  },
  {
    id: 5,
    image: staffImage,
    name: "David Brown",
    position: "Lead Developer",
    description:
      "David Brown is a passionate and creative software engineer with a focus on building innovative and scalable solutions. He is a dedicated and focused individual who thrives on challenging problems and pushing boundaries.Adipisicing in officia esse excepteur ea in non elit proident aliqua dolore sunt est reprehenderit.Exercitation nisi pariatur deserunt commodo. Nulla culpa elit deserunt esse dolore reprehenderit ex cillum velit laborum irure. Qui labore ullamco occaecat ipsum ut aliquip non sit proident adipisicing cupidatat commodo. Anim deserunt laborum esse minim enim do labore esse labore. Anim ad laborum anim adipisicing sit enim do est sit incididunt. Adipisicing nisi commodo laborum nulla consequat. Enim non exercitation occaecat in incididunt.Reprehenderit quis anim Lorem cupidatat voluptate sunt anim do do. Dolore adipisicing cupidatat fugiat et cillum magna. In magna aliqua anim nulla cillum.",
  },
  {
    id: 6,
    image: staffImage,
    name: "Emily Davis",
    position: "CEO",
    description:
      "Emily Davis is a highly skilled and experienced CEO with a focus on building successful and innovative businesses. She is a natural leader and motivator who values collaboration and teamwork.Sit amet veniam ex officia fugiat aliquip non.Deserunt ipsum proident nulla ipsum laboris sunt mollit deserunt anim culpa. Adipisicing occaecat sint adipisicing sit ex non voluptate tempor consequat et. Culpa laboris ipsum laborum mollit elit do officia dolore. Dolor elit nostrud deserunt ea sit sunt aliqua. Reprehenderit nisi amet et proident.Veniam eiusmod mollit pariatur voluptate consectetur enim excepteur laboris enim ea cillum. Consectetur dolor eiusmod ad cupidatat. Lorem esse est eiusmod adipisicing quis sunt pariatur. Dolor aute voluptate proident in proident enim ad duis tempor ex ullamco. Laborum cillum ex qui anim tempor proident excepteur Lorem anim exercitation magna ullamco.",
  },
  {
    id: 7,
    image: staffImage,
    name: "Michael Wilson",
    position: "CTO",
    description:
      "Michael Wilson is a creative and innovative CTO with a focus on building successful and innovative companies. He is a detail-oriented and focused individual who loves to collaborate with team members to create beautiful and intuitive user interfaces.Aliquip est consectetur laborum amet nostrud labore esse elit qui.Occaecat et commodo qui aute ut cupidatat est dolore cupidatat pariatur ex. Culpa do consequat adipisicing officia occaecat ex aliquip esse veniam anim pariatur duis adipisicing. Labore nostrud ut velit minim est amet cupidatat amet est. Magna aliquip pariatur quis amet eiusmod reprehenderit mollit qui ut. Dolor mollit nostrud elit amet officia mollit. Nostrud qui ad laboris do est exercitation incididunt pariatur consequat do.Fugiat eiusmod laborum qui occaecat occaecat esse reprehenderit consequat sunt nostrud. Ut pariatur id non id mollit ad ullamco dolor reprehenderit labore aliqua mollit. Mollit dolore sunt in est veniam esse non eiusmod ullamco in reprehenderit. Et irure ullamco eu nulla qui. Excepteur aute aliqua dolor irure in. Eiusmod sunt occaecat do voluptate magna elit consequat et id. Anim nulla irure id quis nulla labore.",
  },
  {
    id: 8,
    image: staffImage,
    name: "Sarah Thompson",
    position: "HR Manager",
    description:
      "Sarah Thompson is a dedicated and focused HR manager with a focus on helping clients achieve their goals and delivering exceptional customer service. She is a strong team player and motivator who values collaboration and communication.Labore eiusmod anim exercitation sit esse duis in.Ullamco deserunt do officia laborum sunt. Eu mollit Lorem exercitation voluptate ut sunt est. Ex exercitation mollit sint reprehenderit anim nisi ullamco et consequat eu qui ullamco in minim.Incididunt sint deserunt dolor id commodo incididunt amet incididunt quis aute minim. Veniam excepteur et minim officia duis eu dolor. Veniam do incididunt laborum proident. Labore consequat ullamco labore occaecat laborum in tempor ullamco mollit ullamco. Enim culpa quis quis cillum elit ullamco enim aliqua sunt ad elit. Velit ea nostrud adipisicing laboris elit proident tempor cupidatat labore est incididunt eu culpa. Officia amet deserunt aute ipsum deserunt culpa.",
  },
  {
    id: 9,
    image: staffImage,
    name: "David Johnson",
    position: "Lead Developer",
    description:
      "David Johnson is a passionate and creative software engineer with a focus on building innovative and scalable solutions. He is a dedicated and focused individual who thrives on challenging problems and pushing boundaries.Anim anim duis incididunt in excepteur voluptate.In Lorem incididunt ad non officia deserunt sunt laborum. Voluptate nostrud pariatur ut dolore quis. Culpa occaecat commodo pariatur qui do ex. Aliqua irure elit elit magna.Cillum aute adipisicing incididunt elit eu irure labore eu laboris. Duis pariatur consectetur aute pariatur eu deserunt consequat sit qui mollit ut sit. Officia laboris est dolore fugiat ut aliqua. Et fugiat pariatur magna qui consequat proident veniam fugiat cillum enim magna.",
  },
  {
    id: 10,
    image: staffImage,
    name: "Emily Davis",
    position: "CEO",
    description:
      "Emily Davis is a highly skilled and experienced CEO with a focus on building successful and innovative businesses. She is a natural leader and motivator who values collaboration and teamwork.Ullamco eiusmod excepteur et minim eu sunt qui ea qui ullamco proident culpa excepteur sint.Excepteur irure fugiat anim qui anim. Fugiat ut nulla minim laboris incididunt quis deserunt anim laborum voluptate excepteur anim mollit est. In nulla aliqua consectetur magna nulla labore mollit. Aute elit do tempor incididunt adipisicing ullamco nulla aute enim proident anim nostrud do. Cupidatat ad reprehenderit nisi ex elit Lorem velit labore laborum. Nulla do pariatur Lorem commodo ea eiusmod ex amet in enim sit eiusmod. Sint enim incididunt sit consectetur cupidatat est ipsum cupidatat voluptate anim Lorem duis.In ullamco veniam et ex ut aliqua proident excepteur fugiat minim officia tempor cupidatat. Ad amet exercitation ipsum consectetur exercitation minim. Do nisi commodo deserunt enim magna incididunt nostrud eu do tempor sit. Pariatur ut nisi et cupidatat commodo qui voluptate aliqua adipisicing.",
  },
];
