import { useContext } from "react";
import { Outlet } from "react-router-dom";
import AuthContext from "../context/AuthProvider";

function PublicRoute() {
  const { isDark } = useContext(AuthContext);

  return (
    <div data-theme={isDark ? "dark" : "light"}>
      <Outlet />
    </div>
  );
}

export default PublicRoute;
