import React, { useEffect, useCallback, useState } from "react";
import { MdKeyboardArrowRight, MdOutlineMessage } from "react-icons/md";
import { SlHome } from "react-icons/sl";
import { PiToolboxLight } from "react-icons/pi";
import { useLocation, useNavigate } from "react-router-dom";

import "./home.css";
import Home1 from "../../sections/home-1/home-1";
import Home2 from "../../sections/home-2/home-2";
import Home3 from "../../sections/home-3/home-3";
import Footer from "../../components/footer/Footer";
import getInTouch from "../../assets/home-get-in-touch.png";
import image1 from "../../assets/home-news-one.png";
import image2 from "../../assets/home-news-two.png";
import image3 from "../../assets/home-news-three.png";
import image4 from "../../assets/home-news-four.png";
import image5 from "../../assets/home-overview-one.png";
import image6 from "../../assets/home-overview-two.png";
import image7 from "../../assets/home-overview-three.png";
import image8 from "../../assets/home-overview-five.png";

function Home() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const plan = query.get("plan");
  const price = query.get("price");

  const [faq, setFaq] = useState({
    select1: true,
    select2: false,
    select3: false,
    select4: false,
    select5: false,
    select6: false,
  });

  const [formStatus, setFormStatus] = useState({
    submitting: false,
    submitted: false,
  });

  const handleFaqClick = useCallback((option) => {
    setFaq((prevFaq) => ({
      ...prevFaq,
      [option]: !prevFaq[option],
    }));
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    budget: price || "",
    message: plan ? `Hi, I would like to discuss more about the ${plan} plan.` : "",
  });

  useEffect(() => {
    if (plan || price) {
      const formElement = document.getElementById("geetek-form");
      if (formElement) {
        formElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [plan, price]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate form inputs
    if (!formData.name || !formData.phone || !formData.email || !formData.message) {
      alert('Please fill out all required fields.');
      return;
    }
  
    try {
      setFormStatus({ ...formStatus, submitting: true });
  
      const response = await fetch('https://groce-demo.onrender.com/send-geetek-emails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: formData,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to send email');
      }
  
      setFormStatus({ submitting: false, submitted: true });
      setFormData({
        name: "",
        phone: "",
        email: "",
        budget: "",
        message: "",
      });
  
      setTimeout(() => {
        setFormStatus({ ...formStatus, submitted: false });
      }, 5000);
  
    } catch (error) {
      console.error('Error sending email:', error);
      setFormStatus({ submitting: false, submitted: false });
      // Handle error scenario
    }
  };  

  const handleBlogPress = (title, subtitle, image) => {
    navigate('/blog', {
      state: {
        title: title,
        subtitle: subtitle,
        image: image
      }
    });
  }

  return (
    <div>
      <Home1 />
      {/* <Home2 /> */}
      <Home3 />
      <section className="home__section__four">
        <div className="home__section__four__start">
          <div className={`home__section__four__start__one__folder`}>
            <div className="home__section__four__start__one__folder__text">
              <button>OVERVIEW</button>
              <h1>why select us?</h1>
              <h3>Geetek provides best solutions</h3>
            </div>
            <div className="home__section__four__start__one__folder-top"></div>
            <div className="home__section__four__start__one__folder-body">
              <div>
                <h1>97%</h1>
                <h2>Business Growth</h2>
                <h3>
                  You want your business to grow? Let us help you structure with
                  the right technical resource
                </h3>
              </div>
              <img src={image8} alt="alt" />
            </div>
          </div>
          <div className="home__section__four__start__two">
            <img src={image7} alt="alt" />
            <button>Strict Deadline</button>
            <h3>
              we will keep the timelines of deliverables as strict as possible
            </h3>
          </div>
        </div>
        <div className="home__section__four__end">
          <div className="home__section__four__end__one">
            <img src={image5} alt="alt" />
            <button>Strict Deadline</button>
            <h3>
              we will keep the timelines of deliverables as strict as possible
            </h3>
          </div>
          <div className="home__section__four__end__two">
            <div>
              <button>Smart Experience</button>
              <h3>The Best UI and contents for your business is a guarantee</h3>
            </div>
            <img src={image6} alt="alt" />
          </div>
        </div>
      </section>
      <section className="home__section__five">
        <button>BLOG</button>
        <h1>News and Updates</h1>
        <div className="home__section__five__start">
          <img
            src={image1}
            alt="workers"
            className="home__section__five__start__img"
          />
          <div className="home__section__five__start__content">
            <button>UPDATE</button>
            <h1>How to Find Great Webflow Consulting Expert</h1>
            <h3>
              Geetekhub is a management software that helps remote companies
            </h3>
            <button onClick={() => handleBlogPress("How to Find Great Webflow Consulting Expert", "To find an excellent Webflow consulting expert, start by clearly defining your project needs, including your goals, budget, and timeline. Research potential consultants by reviewing their portfolios and client testimonials, and ask for referrals from your network. Verify their credentials and experience, ensuring they have a strong background in Webflow and relevant industry experience. Conduct interviews to assess their expertise and approach to problem-solving. Request references and follow up to understand their previous clients' experiences. Ensure the consultant is a good fit for your team and review proposals and contracts carefully before making a decision. Starting with a small project can help you evaluate their performance before committing to a larger engagement. Geetekhub: Management Software for Remote Companies Geetekhub is a comprehensive management software designed to enhance the productivity and efficiency of remote companies. It offers robust project management tools, team collaboration features like chat and video conferencing, and time tracking for accurate billing and productivity analysis. Resource management tools ensure optimal utilization of team skills, while document management keeps important files organized and secure. Geetekhub also includes client management for maintaining detailed client profiles, financial management for invoicing and expense tracking, and customizable dashboards for real-time business insights. With integration capabilities and strong security features, Geetekhub is a valuable tool for streamlining remote operations and improving team collaboration.", image1)}>Read More</button>
          </div>
        </div>
        <div className="home__section__five__end">
          <div className="home__section__five__end__block">
            <img
              src={image2}
              alt="workers"
              className="home__section__five__end__block__img"
            />
            <button>STUDIO</button>
            <h1>From Zero to Hero: How to Launch a Successful Startup</h1>
            <h2>Discover the essential steps and strategies to turn your startup idea into a thriving business</h2>
            <h3 onClick={() => handleBlogPress(
    "From Zero to Hero: How to Launch a Successful Startup", 
    "Discover the essential steps and strategies to turn your startup idea into a thriving business. From planning and funding to marketing and scaling, this guide covers everything you need to know to launch a successful startup.", 
    image2
)}>READ MORE</h3>
          </div>
          <div className="home__section__five__end__block">
            <img
              src={image3}
              alt="workers"
              className="home__section__five__end__block__img"
            />
            <button>DESIGN</button>
            <h1>7 habits of Highly Successful Business People</h1>
            <h2>Learn the key habits that drive success in the business world</h2>
            <h3 onClick={() => handleBlogPress(
                "7 Habits of Highly Successful Business People", 
                "Learn the key habits that drive success in the business world. From effective time management and goal setting to continuous learning and networking, discover the practices that highly successful business people incorporate into their daily routines.", 
                image3
            )}>READ MORE</h3>
          </div>
          <div className="home__section__five__end__block">
            <img
              src={image4}
              alt="workers"
              className="home__section__five__end__block__img"
            />
            <button>DESIGN</button>
            <h1>7 habits of Highly Successful Business People</h1>
            <h2>Pulsar is a Webflow No Code Template for Creatives like you</h2>
            <h3 onClick={() => handleBlogPress("7 habits of Highly Successful Business People", "Pulsar is a Webflow No Code Template for Creatives like you", image4)}>READ MORE</h3>
          </div>
        </div>
      </section>
      <section className="home__section__six">
        <div className="home__section__six__start">
          <div className="home__section__six__start__profile">
            <img src={getInTouch} alt="get in touch profile" />
          </div>
          <h1>Get in touch with us!</h1>
          <p>We are waiting for your message</p>
          <span className="home__section__six__start__span">
            <SlHome className="home__section__six__start__span__icon" />
            <div>
              <h3>Address</h3>
              <p>London, United Kingdom</p>
            </div>
          </span>
          <span className="home__section__six__start__span">
            <PiToolboxLight className="home__section__six__start__span__icon" />
            <div>
              <h3>Phone</h3>
              <p>+44 7753 140131 / +234 818 446 1131</p>
            </div>
          </span>
          <span className="home__section__six__start__span">
            <MdOutlineMessage className="home__section__six__start__span__icon" />
            <div>
              <h3>Email</h3>
              <p>info@geetekglobal.com</p>
            </div>
          </span>
        </div>
        <div className="home__section__six__end">
          <form onSubmit={handleSubmit} id="geetek-form">
          <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              disabled={formStatus.submitting}
            />
            <input
              type="number"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
              disabled={formStatus.submitting}
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              disabled={formStatus.submitting}
            />
            <input
              type="text"
              name="budget"
              placeholder="Budget"
              value={formData.budget}
              onChange={handleChange}
              disabled={formStatus.submitting}
            />
            <textarea
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
              disabled={formStatus.submitting}
            ></textarea>
            <button type="submit" disabled={formStatus.submitting}>
              {formStatus.submitting ? 'Submitting...' : 'Submit Now'}
            </button>
            {formStatus.submitted && (
              <div className="success-message">Email sent successfully!</div>
            )}
          </form>
        </div>
      </section>
      <section className="home__section__seven">
        <h1>Frequently Asked Question</h1>
        <div
          className={`home__section__seven__faq${
            faq.select1 ? "" : " notActive"
          }`}
        >
          <div
            className="home__section__seven__faq__ques"
            onClick={() => handleFaqClick("select1")}
          >
            <p>What products and services does your company offer?</p>
            <MdKeyboardArrowRight
              className={
                faq.select1
                  ? "home__section__seven__faq__icon clicked"
                  : "home__section__seven__faq__icon"
              }
            />
          </div>
          <div
            className={
              faq.select1
                ? "home__section__seven__faq__ans clicked"
                : "home__section__seven__faq__ans"
            }
          >
            <p>Geetek Global offers a range of services, including software solutions, custom web and mobile app development, digital marketing and business consulting services. We create tailored solutions for businesses looking to establish a strong online presence or enhance their mobile capabilities. Clients can explore our portfolio of past projects on our website to see samples of our work.</p>
          </div>
        </div>
        <div
          className={`home__section__seven__faq${
            faq.select2 ? "" : " notActive"
          }`}
        >
          <div
            className="home__section__seven__faq__ques"
            onClick={() => handleFaqClick("select2")}
          >
            <p>How can clients  get technical support?</p>
            <MdKeyboardArrowRight
              className={
                faq.select2
                  ? "home__section__seven__faq__icon clicked"
                  : "home__section__seven__faq__icon"
              }
            />
          </div>
          <div
            className={
              faq.select2
                ? "home__section__seven__faq__ans clicked"
                : "home__section__seven__faq__ans"
            }
          >
            <p>Customers can get technical support by contacting our customer support team through email, phone, or live chat on our website. For common issues, we have detailed troubleshooting guides available on our support page. Our customer support team is available to assist with any technical queries.</p>
          </div>
        </div>
        <div
          className={`home__section__seven__faq${
            faq.select3 ? "" : " notActive"
          }`}
        >
          <div
            className="home__section__seven__faq__ques"
            onClick={() => handleFaqClick("select3")}
          >
            <p>How can clients pay for products and services?</p>
            <MdKeyboardArrowRight
              className={
                faq.select3
                  ? "home__section__seven__faq__icon clicked"
                  : "home__section__seven__faq__icon"
              }
            />
          </div>
          <div
            className={
              faq.select3
                ? "home__section__seven__faq__ans clicked"
                : "home__section__seven__faq__ans"
            }
          >
            <p>We offer transparent billing practices for all our  services. Clients can choose from different service packages based on the scope of their project, and pay in three milestone payments, or fixed project fees. Our billing process is outlined clearly in our service agreements to ensure clarity for our clients.</p>
          </div>
        </div>
        <div
          className={`home__section__seven__faq${
            faq.select4 ? "" : " notActive"
          }`}
        >
          <div
            className="home__section__seven__faq__ques"
            onClick={() => handleFaqClick("select4")}
          >
            <p>How long does it take to complete a project?</p>
            <MdKeyboardArrowRight
              className={
                faq.select4
                  ? "home__section__seven__faq__icon clicked"
                  : "home__section__seven__faq__icon"
              }
            />
          </div>
          <div
            className={
              faq.select4
                ? "home__section__seven__faq__ans clicked"
                : "home__section__seven__faq__ans"
            }
          >
            <p>Our standard delivery time for website and mobile app development is 4 to 6 weeks. This time frame allows for a comprehensive development process, including design, implementation, testing, and revisions, ensuring a high-quality end product for our clients. However, the time frame differs with the projects.</p>
          </div>
        </div>
        <div
          className={`home__section__seven__faq${
            faq.select5 ? "" : " notActive"
          }`}
        >
          <div
            className="home__section__seven__faq__ques"
            onClick={() => handleFaqClick("select5")}
          >
            <p>How is the client's data protected?</p>
            <MdKeyboardArrowRight
              className={
                faq.select5
                  ? "home__section__seven__faq__icon clicked"
                  : "home__section__seven__faq__icon"
              }
            />
          </div>
          <div
            className={
              faq.select5
                ? "home__section__seven__faq__ans clicked"
                : "home__section__seven__faq__ans"
            }
          >
            <p>Client data security is paramount and always considered in any of our service development processes. We adhere to strict security protocols to safeguard sensitive information and ensure client confidentiality. Our development team follows industry best practices to protect client data from unauthorized access or breaches.</p>
          </div>
        </div>
        <div
          className={`home__section__seven__faq${
            faq.select6 ? "" : " notActive"
          }`}
        >
          <div
            className="home__section__seven__faq__ques"
            onClick={() => handleFaqClick("select6")}
          >
            <p>What is the company's history?</p>
            <MdKeyboardArrowRight
              className={
                faq.select6
                  ? "home__section__seven__faq__icon clicked"
                  : "home__section__seven__faq__icon"
              }
            />
          </div>
          <div
            className={
              faq.select6
                ? "home__section__seven__faq__ans clicked"
                : "home__section__seven__faq__ans"
            }
          >
            <p>Our company has a proven track record of delivering innovative tech solutions to a diverse range of clients. We prioritize client satisfaction and collaboration throughout the development process, ensuring that each project meets the unique needs and goals of our clients. Clients can connect with us through our website to learn more about our services and discuss their project requirements.</p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Home;
