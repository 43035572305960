import React, { useState } from "react";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { TbBrandInstagram } from "react-icons/tb";
import "./footer.css";
import newsletterSendIcon from "../../assets/footer-newsletter-send-icon.png";
import footerLogo from "../../assets/logodark.png";
import { useNavigate, useLocation } from "react-router-dom";

function Footer() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Handles email input and sets email to input value
   * @param {object} event - The input change event
   */
  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    //Submit logic
  };

  const handleNavigation = (path) => {
    navigate(`/${path}`);
    window.scrollTo(0, 0);
  };

  const handleButtonClick = () => {
    window.open("https://wa.me/qr/CDUXCHGLTIMRE1", "_blank");
  };

  const facebookClick = () => {
    window.open("https://www.facebook.com/people/Geetek-Global/100089356900969/", "_blank");
  }

  const instaClick = () => {
    window.open("https://www.instagram.com/geetekglobal/", "_blank");
  }

  const linkedInClick = () => {
    window.open("https://www.linkedin.com/company/100009367/admin/feed/posts/", "_blank");
  }

  return (
    <footer className="footer">
      <section className="footer__main">
        <div className="footer__main__start">
          <img src={footerLogo} alt="footer-logo" />
          <p>Software</p>
          <p>Web</p>
          <p>Brand</p>
          <p>Media</p>
          <span className="footer__main__start__social__media">
            <FaFacebookF onClick={facebookClick}/>
            <TbBrandInstagram onClick={instaClick}/> 
            <FaLinkedinIn onClick={linkedInClick}/>
          </span>
        </div>
        <div className="footer__main__end">
          <div className="footer__main__end__block">
            <h1>Quick Links</h1>
            <p onClick={() => handleNavigation("")}>Home</p>
            <p onClick={() => handleNavigation("about")}>About</p>
            <p onClick={() => handleNavigation("work")}>Work</p>
            <p onClick={() => handleNavigation("pricing")}>Pricing</p>
          </div>
          <div className="footer__main__end__block">
            <h1>Services</h1>
            <p>Web Development</p>
            <p>Graphics Design</p>
            <p>Digital Marketing</p>
            <p>UI/UX Design</p>
          </div>
          <div className="footer__main__end__block">
            <h1>Support</h1>
            <p onClick={handleButtonClick}>Contact Us</p>
          </div>
          <div className="footer__main__end__block">
            <h1>Newsletter</h1>
            <p>Subscribe to receive future updates</p>
            <span className="footer__main__end__block__newsletter__input__container">
              <input
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={handleInputChange}
              />
              <img
                src={newsletterSendIcon}
                alt="Send icon"
                onClick={handleSubmit}
              />
            </span>
          </div>
        </div>
      </section>
      <section className="footer__copyright">
        <div className="footer__copyright__links">
          <p>English</p>
          <p>Privacy</p>
          <p>Support</p>
        </div>
        <div className="footer__copyright__text">
          <p>&copy; Geetek Global. All right reserved</p>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
